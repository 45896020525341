@import 'colors.scss';

.label {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: $dark;
  text-align: left;
}