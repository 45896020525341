@import 'fonts.scss';

.searchKeywordsComponent {
  height: 100%;
  padding: 12px;
  position: relative;
  display: flex;
  flex-direction: column;
  .title {
    @include base-font;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.2;
    color: $black;
  }
  hr {
    margin: 15px 0px;
  }
  .body {
    position: relative;
    flex-grow: 1;
    .descript {
      @include base-font;
      font-size: 16px;
      color: #212529;
      font-weight: 500;
      font-family: PingFangTC;
      margin-bottom: 10px;
    }
    .row {
      display: flex;
      flex-direction: row;
      input {
        height: 34px;
      }
      .addButton {
        width: 34px;
        height: 34px;
        margin-left: 10px;
        background-color: $primary1;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        cursor: pointer;
        padding: 10px;
        svg {
          color: $white;
          width: 12px;
        }
      }
    }
    .bottom {
      position: absolute;
      bottom: 0px;
    }
  }
  .relatedKeywordsContainer {
    margin-top: 70px;
    height: 294px;
    position: relative;
    .relatedKeywordsTitle {
      font-family: PingFangTC;
      font-size: 16px;
      font-weight: 500;
      color: #212529;
      margin-bottom: 10px;
    }
    .relatedKeywords {
      height: 262px;
      overflow: auto;
    }
    .relatedKeyword {
      @include base-font;
      height: 34px;
      font-size: 14px;
      line-height: 1.57;
      color: $black;
      cursor: pointer;
      display: flex;
      > div:first-child {
        width: 40%;
      }
      > div:nth-child(2) {
        flex-grow: 1;
      }
      > div:last-child {
        margin-right: 14px;
        svg {
          color: $dark;
          font-size: 14px;
        }
      }
      &.disabled {
        color: $super-light;
        cursor: default;
        > div:last-child {
          svg {
            color: $super-light;
          }
        }
      }
    }
    .loadingIndicator {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1070;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .roller {
        margin: auto;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        border: 4px solid $theme1;
        animation-name: roller;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-fill-mode: forwards;
        border-left-color: transparent !important;
      }

      @keyframes roller {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
}