@import 'fonts.scss';

.productGroupForm {
  background-color: $white3;
  .titleArea {
    color: $black;
    height: 84px;
    padding: 30px;
    box-shadow: 2px 2px 5px 0 rgba(97, 95, 103, 0.5);
    background-color: $white;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;

    .title {
      @include base-font;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.2;
    }
  }
  .formContent {
    background-color: $white;
    box-shadow: 2px 2px 5px 0 rgba(97, 95, 103, 0.5);
    margin-top: 30px;
    margin-bottom: 60px;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .formButtons {
    margin-left: 30px;
    button,
    a {
      margin-left: 20px;
    }

    padding-bottom: 60px;
  }

  input {
    width: 600px;
  }
  textarea {
    width: 600px;
    height: 100px;
  }
  .selectField {
    width: 600px;
  }
  .row {
    display: flex;
    button {
      width: 140px;
      @include base-font;
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
      color: $dark;
      height: 41px;
      margin-left: 20px;
      border-color: $super-light;
      border-radius: 3px;
      flex-shrink: 0;
      padding: 0;
    }
    .selectField {
      width: 440px;
    }
  }
}

.productGroupCustomModal {
  :global(.modal-footer) {
    display: none;
  }
  :global(.modal-body) {
    padding: 0;
  }
}

.modalContentArea {
  padding: 20px;
}

.modalButtonArea {
  padding: 20px;
  border-top: 1px solid $super-light;
  button {
    margin-right: 20px;
  }
}