@import 'fonts.scss';
@import 'colors.scss';
@import 'components.scss';

.reportTable {
  width: 100%;
  height: 100%;
  background-color: $white2;
  display: flex;
  flex-direction: column;

  .reportList {
    border-bottom: 1px solid $super-light;
  }

  @include tabs;
  :global(.nav) {
    padding-top: 28px;
    :global(.nav-item) {
      white-space: nowrap;
    }
  }

  .reportCell {
    min-width: 90px;
    padding-right: 10px;
    padding-left: 15px;
    white-space: nowrap;
    vertical-align: middle;
    text-align: right;
  }

  .tableContainer {
    max-height: calc(100% - 70px);
  }

  .name {
    padding-left: 24px;
    text-align: left;
  }

  .tags {
    min-width: 250px;
    text-align: left;
  }

  tfoot {
    .name {
      background-color: $super-light;
    }
  }
  .nameCell {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .nameValue {
      @include base-font;
      font-size: 14px;
      line-height: 1.57;
      color: $theme1;
      cursor: pointer;
      & ~ svg {
        cursor: pointer;
        visibility: hidden;
      }
      min-width: 186px;
      max-width: 386px;
      overflow-wrap: break-word;
      white-space: normal;
    }
  }

  .editBtns {
    min-width: 0;
    max-width: 0;
    width: 0;
    padding: 0px;
    .floatingEditArea {
      display: none;
    }
  }

  tr:hover {
    .nameCell > .nameValue ~ svg {
      visibility: visible;
    }
    .floatingEditArea {
      display: flex;
    }
  }

  .tagsContent {
    display: flex;
    flex-wrap: wrap;
    max-width: 172px;
    div {
      height: 18px;
      border-radius: 12px;
      background-color: $light;
      @include base-font;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.17;
      color: $white;
      padding: 0 10px;
      display: flex;
      align-items: center;
      margin: 0 5px 5px 0;
    }
  }
}