@import 'colors.scss';

.container {
  display: inline-flex;
  align-items: center;
}

.wording {
  height: 22px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: $dark;
}