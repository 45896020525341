@import 'colors.scss';
@import 'fonts.scss';

.advertiserSegmentSourceContainer {
  :global(.row) {
    margin-left: 0;
    margin-right: 0;
  }
  form {
    padding-top: 20px;
  }
  :global(.form-group.row) {
    label {
      color: $dark;
    }
  }
  .sourceAdder {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: $theme2;
    font-size: 14px;
    @include base-font;
    width: fit-content;
    height: 37px;
    margin-bottom: 1rem;
    svg {
      margin-right: 6px;
    }
  }
  .formField {
    .removeBtn {
      font-size: 14px;
      @include base-font;
      color: $theme2;
      margin-left: 7px;
      margin-top: 2px;
      cursor: pointer;
    }
  }
  .advertiserList {
    margin-top: 6px;
  }
}