@import 'fonts.scss';

.tiktokAdGroupList {
  $topAreaHeight: 77px;
  min-height: 100%;
  background-color: $white3;
  display: flex;
  flex-direction: column;
  .contentArea {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .metricsOptionsContainer {
      background-color: $white;
      height: 72px;
      border-bottom: solid 1px #979797;
      flex-shrink: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 30px;
      .metricsOptions {
        width: 200px;
        z-index: 10;
      }
    }
  }
  thead th,
  td {
    &.editBtns {
      width: 0;
      min-width: 0;
      max-width: 0;
      padding: 0px;
    }
    &.state, &.delivery, &.enableCount {
      min-width: 120px;
    }
    &.adgroup_id {
      min-width: 250px;
      max-width: 360px;
    }
    &.schedule_start_time {
      min-width: 170px;
    }
    &.optimize_goal {
      min-width: 320px;
    }
    &.budget {
      white-space: nowrap;
    }
    &.lifetime_budget,
    &.result,
    &.lifetimeReach,
    &.impressions,
    &.clicks,
    &.cpc,
    &.ctr,
    &.videoPlayActions,
    &.videoWatched2s,
    &.videoWatched6s,
    &.videoViewsP25,
    &.videoViewsP50,
    &.videoViewsP75,
    &.videoViewsP100,
    &.profileVisits,
    &.likes,
    &.comments,
    &.shares,
    &.follows,
    &.clicksOnMusicDisc,
    &.spend {
      white-space: nowrap;
    }
    &.result,
    &.lifetimeReach,
    &.impressions,
    &.clicks,
    &.cpc,
    &.ctr,
    &.videoPlayActions,
    &.videoWatched2s,
    &.videoWatched6s,
    &.videoViewsP25,
    &.videoViewsP50,
    &.videoViewsP75,
    &.videoViewsP100,
    &.profileVisits,
    &.likes,
    &.comments,
    &.shares,
    &.follows,
    &.clicksOnMusicDisc,
    &.spend {
      text-align: right;
    }
  }
  :global(.summaryRow) {
    .id,
    .listId {
      div {
        padding-left: 48px;
      }
    }
  }
  th:first-child,
  td:first-child {
    padding-left: 20px;
  }
  .floatingEditArea {
    display: none;
  }
  .selectCheckBox {
    min-width: 48px;
    height: auto;
    input {
      display: none;
    }
    input + label {
      margin-right: 0;
    }
    input:checked + label::before,
    input:indeterminate + label::before {
      background-color: $theme1;
      border: 1px solid $theme1;
    }
    input:indeterminate + label::after {
      content: '';
      position: absolute;
      left: 2px;
      top: 13px;
      width: 1px;
      height: 1px;
      box-shadow: 1px 0 0 $white, 2px 0 0 $white, 3px 0 0 $white,
        4px 0 0 $white, 5px 0 0 $white, 6px 0 0 $white, 7px 0 0 $white,
        8px 0 0 $white;
    }
  }
  .nameHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .nameCell {
    display: flex;
    flex-direction: row;
    align-items: center;
    .name {
      @include base-font;
      font-size: 14px;
      line-height: 1.57;
      color: $theme1;
      display: block;
      max-width: 360px;
      word-break: break-word;
    }
    .id,
    .listId {
      @include base-font;
      font-size: 10px;
      font-weight: 500;
      line-height: 1.2;
      color: $dark;
      padding: 4px 0px;
    }
    .draftIcon {
      width: max-content;
      padding: 2px 10px;
      font-size: 12px;
      line-height: 1.17;
      color: $theme1;
      border-radius: 12px;
      border: solid 1px $theme1;
      margin: 5px 0px;
    }
  }
  .resultCell {
    position: relative;
    .value {
      color: $dark;
    }
    .objective {
      font-size: 10px;
      color: $light;
      position: absolute;
      right: 0;
    }
  }
  .bindingCell {
    display: flex;
    flex-direction: row;
    align-items: center;
    .campaignEditBtn,
    .detailBtn {
      display: none;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-left: 10px;
      color: $dark;
    }
  }
  .optimizationCell {
    display: grid;
    grid-template-columns: auto 10px 1fr;
    .title {
      grid-column: 1/2;
      padding-right: 5px;
    }
  }
  tr:hover {
    .floatingEditArea,
    .campaignEditBtn,
    .detailBtn {
      display: flex;
    }
    td {
      background-color: $white2;
    }
    &.draftBg,
    &.lightDanger {
      background-color: $white2;
      .id,
      .listId {
        background-color: $white2 !important;
      }
    }
  }
  tr.lightDanger {
    background-color: $light-danger;
    .id,
    .listId {
      background-color: $light-danger !important;
    }
  }
  tr.draftBg {
    background-color: $theme3;
    .id,
    .listId {
      background-color: $theme3 !important;
    }
  }
  .titleBar {
    width: 100%;
    flex-shrink: 0;
    border-bottom: 1px solid #dee2e6;
    height: $topAreaHeight;
    position: relative;
    background-color: $white;
    display: flex;
    flex-direction: row;
    align-items: center;
    .deleteDraftTab {
      margin-left: auto;
      svg {
        margin-right: 6px;
      }
    }
  }
  .filterArea {
    display: flex;
    align-items: center;
    background-color: $white;
    :global(.search-bar) {
      width: 370px;
      margin: 0px;
      position: absolute;
      right: 30px;
    }
    .createBtn {
      color: $white;
    }
  }
  .createAdGroup {
    margin-left: 30px;
    display: inline-block;
  }
  .tipContainer {
    position: relative;
  }
  .tipContainer.block {
    &:hover {
      cursor: not-allowed;
      .tooltip {
        display: block;
      }
    }
    a {
      pointer-events: none;
      background-color: $theme6;
      border-color: $theme6;
    }
  }
  .tooltip {
    display: none;
    position: absolute;
    left: 65px;
    top: 20px;
    z-index: 2;
    height: 15px;
    font-size: 12px;
    font-weight: 500;
    @include base-font;
    line-height: 1.17;
    color: $dark;
    background-color: $white;
    padding: 0 5px;
    white-space: nowrap;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: $black;
    border-radius: 0.25rem;
    top: unset;
    bottom: -25px;
    height: auto;
  }
}

.deliveryAdReviewSection {
  margin-bottom: 10px;
  ul {
    padding-left: 20px;
    margin-bottom: 0;
  }
  li {
    overflow-wrap: break-word;
  }
}
