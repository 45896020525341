@import "../../../colors.scss";

:global {
  .react-toggle {
    touch-action: pan-x;

    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
  }

  .react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
  }

  .react-toggle-track {
    width: 59px;
    height: 22px;
    padding: 0;
    border-radius: 30px;
    background-color: $light;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
    color: $white;
  }

  .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: $dark;
  }

  .react-toggle--checked .react-toggle-track {
    background-color: $theme2;
  }

  .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: $theme1;
  }

  .react-toggle-track-check {
    position: absolute;
    width: 14px;
    height: 10px;
    top: 11px;
    left: 10px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  .react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  .react-toggle-track-x {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 11px;
    left: 22px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  .react-toggle--checked .react-toggle-track-x {
    opacity: 0;
  }

  .react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 2px;
    left: 2px;
    width: 18px;
    height: 18px;
    border: 1px solid $light;
    border-radius: 50%;
    background-color: $white;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }

  .react-toggle--checked .react-toggle-thumb {
    left: 38px;
    top: 2px;
    border-color: $theme2;
  }
}

:global {
  .largeToggle {
    .react-toggle-track {
      width: 78px;
      height: 34px;
    }

    .react-toggle-thumb {
      width: 28px;
      height: 28px;
      top: 3px;
      left: 3px;
    }

    .react-toggle-track-check {
      top: 16px;
      left: 14px;
    }

    .react-toggle-track-x {
      top: 16px;
      left: 35px;
    }

    &.react-toggle--checked .react-toggle-thumb {
      left: 47px;
      top: 3px;
    }
  }
}