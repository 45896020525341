@import 'colors.scss';
@import 'fonts.scss';

.stickableTableContainer {
  position: relative;
  width: 100%;
  display: flex;
  &.placeholder {
    height: 100%;
  }
  &.paddingBottom {
    padding-bottom: 60px;
  }
  .stickableTable {
    background-color: $white;
    width: 100%;
    height: 100%;
    overflow-x: auto;
    box-shadow: 2px 2px 5px 0 rgba(97, 95, 103, 0.5);

    &.scrollByContainer {
      overflow-x: hidden;
    }

    &.horizontal {
      width: auto;
      height: 100%;
    }

    &.stickHeader {
      thead > tr > th {
        background-color: $white;
        position: sticky;
        top: 0;
        z-index: 2;
      }
    }

    &.stickHeader.showDivider {
      thead > tr > th {
        &::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 1px;
          display: block;
          background-color: $white;
          box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5);
          border-top: solid 1px $super-light;
        }
      }
    }

    &.stickFooter {
      tbody > tr:last-child > td {
        position: sticky;
        bottom: 0;
        z-index: 2;
        background-color: $white;
      }
    }

    &.stickFirstColumn {
      thead > tr > th:nth-child(1),
      tbody > tr > td:nth-child(1),
      tfoot > tr > th:nth-child(1) {
        position: sticky;
        left: 0;
        z-index: 2;
      }

      thead > tr > th:nth-child(1),
      tbody > tr > td:nth-child(1) {
        background-color: $white;
      }

      tbody > tr:hover > td:nth-child(1) {
        background-color: $white2;
      }
    }

    &.stickFirstColumn.showDivider {
      thead > tr > th:nth-child(1),
      tbody > tr > td:nth-child(1),
      tfoot > tr > th:nth-child(1) {
        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          width: 1px;
          height: 100%;
          display: block;
          background-color: $white;
          box-shadow: 1px 0 5px 0 rgba(0, 0, 0, 0.5);
          border-left: solid 1px $super-light;
        }
      }
    }

    &.stickLastColumn {
      thead > tr > th:last-child,
      tbody > tr > td:last-child,
      tfoot > tr > th:last-child {
        position: sticky;
        right: 0;
        z-index: 2;
      }
    }

    &.stickHeader.stickFirstColumn {
      thead > tr > th:nth-child(1) {
        z-index: 3;
      }
    }

    &.stickHeader.stickLastColumn {
      thead > tr > th:last-child {
        z-index: 3;
      }
    }

    &.stickFooter.stickFirstColumn {
      tbody > tr:last-child > td:nth-child(1) {
        z-index: 3;
      }
    }

    &.stickFooter.stickLastColumn {
      tbody > tr:last-child > td:last-child {
        z-index: 3;
      }
    }
  }

  :global(.react-bootstrap-table-pagination) {
    div:first-child {
      display: none;
    }
    right: 0;
  }

  :global(.table-bordered) {
    margin-bottom: 0;
    box-shadow: none;
  }

  :global(.react-bootstrap-table-pagination-list) {
    max-width: 100%;
    display: flex;
    flex: auto;
    justify-content: flex-end;
    padding-right: 30px;
    margin-top: 20px;
    position: absolute;
    bottom: 15px;
    :global(.pagination) {
      margin: 0;
    }
  }

  th {
    user-select: none;
    outline: none;
    > span {
      display: inline-block;
      width: 20px;
    }
  }

  td {
    vertical-align: middle;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  tfoot {
    tr {
      background-color: $super-light;
      th {
        border: none;
        @include base-font;
        font-size: 14px;
        line-height: 1.57;
        color: $black;
        font-weight: normal;
      }
    }
  }

  tr:hover {
    td {
      background-color: $white2;
    }
  }
}
:global {
  .react-bootstrap-table .reset-expansion-style {
    padding: 0;
  }

  .react-bootstrap-table .row-expand-slide-appear {
    max-height: 0;
    overflow: hidden;
  }

  .react-bootstrap-table .row-expand-slide-appear-active {
    max-height: 1000px;
    transition: max-height 3s linear;
  }

  .react-bootstrap-table .row-expand-slide-exit {
    max-height: 1000px;
  }

  .react-bootstrap-table .row-expand-slide-exit-active {
    max-height: 0;
    overflow: hidden;
    transition: max-height 400ms cubic-bezier(0, 0.95, 0, 0.95);
  }

  .summaryRow {
    pointer-events: none;
    height: 32px;
    background-color: $super-light;
    td {
      @include base-font;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.17;
      color: $dark;
    }
    td:first-child {
      background-color: $super-light !important;
    }
  }
}
