@import 'fonts.scss';

.imageCreativeSizeHint {
  width: 462px;
  .title {
    border-bottom: 1px solid $super-light;
    width: 100%;
    height: 66px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 19px; 
    padding-right: 22px;
    span {
      @include base-font;
      font-size: 20px;
      font-weight: 500;
      color: $black;
    }
    svg {
      font-size: 14px;
      color: $dark;
      cursor: pointer;
    }
  }
  .sectionTitle {
    @include base-font;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.57;
    color: $black;
    margin-top: 46px;
    margin-bottom: 16px;
  }
  .recommendSize {
    display: flex;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 50px;
    .recommendSizeContent {
      display: grid;
      width: 364px;
      grid-template-rows: 140px 50px 45px;
      grid-template-columns: 183px auto;
      grid-row-gap: 22px;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        @include base-font;
        font-size: 14px;
        line-height: 1.57;
        color: $dark;
        border: 1px solid $super-light;
        align-self: start;
        justify-self: center;
      }
      .size336x280 {
        width: 168px;
        height: 140px;
        grid-row: 1 / 2;
        grid-column: 1 / 2;
      }
      .size300x250 {
        width: 150px;
        height: 125px;
        grid-row: 1 / 2;
        grid-column: 2 / 3;
      }
      .size320x100 {
        width: 160px;
        height: 50px;
        grid-row: 2 / 3;
        grid-column: 2 / 3;
      }
      .size320x50 {
        width: 160px;
        height: 25px;
        grid-row: 2 / 3;
        grid-column: 1 / 2;
      }
      .size728x90 {
        width: 364px;
        height: 40px;
        grid-row: 3 / 4;
        grid-column: 1 / 3;
      }
      .size160x600 {
        width: 70px;
        height: 266px;
        grid-row: 4 / 5;
        grid-column: 1 / 2;
      }
      .size468x60 {
        width: 208px;
        height: 26px;
        grid-row: 4 / 5;
        grid-column: 2 / 3;
      }
    }
  }
  .otherSize {
    padding-left: 39px;
    .otherSizeContent {
      width: 300px;
      display: flex;
      flex-wrap: wrap;
      .otherSizeInstance {
        div {
          width: 15px;
          height: 15px;
          background-color: $dark;
          display: inline-block;
          margin-right: 15px;
        }
        display: flex;
        align-items: center;
        width: 106px;
        margin-right: 23px;
        padding-bottom: 23px;
        @include base-font;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.57;
        color: $dark;
      }
    }
    padding-bottom: 22px;
  }
}