@import 'fonts.scss';

.campaignGroupForm {
  background-color: $white2;
  min-height: 100%;
  .titleArea {
    color: $black;
    height: 118px;
    box-shadow: 2px 2px 5px 0 rgba(97, 95, 103, 0.5);
    background-color: $white;
  }
  .formContent {
    background-color: $white;
    box-shadow: 2px 2px 5px 0 rgba(97, 95, 103, 0.5);
    margin-top: 30px;
    margin-bottom: 60px;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .formButtons {
    margin-left: 30px;
    button,
    a {
      margin-left: 20px;
    }

    padding-bottom: 60px;
  }

  .adSetBudgetContainer {
    display: flex;
    .adSetBudget {
      display: block;
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.17;
      letter-spacing: normal;
      color: #98969d;
      margin-top: 2px;
      margin-right: 20px;
      div {
        padding-bottom: 5px;
      }
    }
    svg {
      align-self: center;
    }
  }
}
