@import 'colors.scss';
@import 'fonts.scss';

.login-background {
  overflow: hidden;
  min-height: 740px;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: $theme5;
  user-select: none;

  .form-image {
    height: 510px;
    margin: auto;
    flex-grow: 1;
    object-fit: cover;
    &.fill {
      width: 574px;
      height: 640px;
    }
  }

  &.gojek {
    background-color: #279F00;
  }

  .right-top {
    position: absolute;
    right: 0;
    top: 0;
  }

  .left-bottom {
    position: absolute;
    left: 0;
    bottom: 0;
    transform: rotate(180deg);
  }

  .form-container {
    z-index: 1;
    margin: auto;
    display: flex;
    flex-direction: row;
    background-color: $white;
    width: 1024px;
    height: 640px;
    border-radius: 10px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
    position: relative;
  }

  form {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .loginPagePrivacyPolicy {
    position: absolute;
    bottom: -26px;
    right: 12px;
  }
}

@media (max-width: 1024px) {
  .login-background {
    .right-top, .left-bottom {
      display: none;
    }
    background-color: $theme5;
    padding: 24px;
    padding-bottom: 38px;
    width: 100%;
    height: 100%;
    .form-container {
      flex-direction: column;
      width: 100%;
      height: 100%;
    }
    .form-image {
      width: auto;
      max-height: 450px;
      overflow: hidden;
      object-fit: cover;
      border-bottom-left-radius: 0px;
      border-top-right-radius: 10px;
    }
    .loginForm {
      flex-grow: 1;
      form {
        width: 100%;
      }
    }
    .form-title {
      margin-top: 60px
    }
    form {
      border-top-right-radius: 0px;
      border-bottom-left-radius: 10px;
    }
  }
}

@media (max-width: 640px) {
  .login-background {
    padding: 12px;
    padding-bottom: 38px;
    .form-image {
      width: auto;
      max-height: 250px;
      overflow: hidden;
      object-fit: cover;
    }
  }
}