@import "colors.scss";
@import 'fonts.scss';

.inventoryContainer {
  height: 556px;
  width: 580px;
  display: flex;
  border: 1px solid $super-light;

  .content {
    width: 100%;

    :global {

      .loading-indicator {
        position: static;
        background-color: unset;

        .board {
          box-shadow: unset;
          width: auto;
        }
      }
    }
  }

  >.content>div {
    width: 100%;
    height: 100%;
    border: none;
  }
}

.sidebar {
  width: 220px;
  white-space: nowrap;
  border-right: 1px solid $super-light;
  display: flex;
  flex-direction: column;

  >.title {
    width: 100%;
    background-color: $light;
    display: flex;
    align-items: center;
    flex-basis: 34px;

    >span {
      @include base-font;
      height: 14px;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.17;
      color: $white;
      margin-left: 12px;
    }
  }

  >.content {
    overflow-y: auto;
    flex: 1;

    >.itemContainer {
      min-height: 34px;
      padding: 8px 8px 8px 12px;

      >.item {
        @include base-font;
        height: 22px;
        font-size: 13.5px;
        font-weight: normal;
        line-height: 1;
        color: $black;
        white-space: normal;
      }

      &:hover {
        border-radius: 2px;
        background-color: $white2;
        cursor: pointer;
      }

      &.active {
        background-color: $white2;

        >.item {
          color: $theme1;
        }
      }
    }
  }

  >.total {
    width: 100%;
    flex-basis: 23px;
    border-top: 1px solid $super-light;
    display: flex;
    align-items: center;

    >span {
      @include base-font;
      height: 14px;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.17;
      color: $dark;
      margin-left: 12px;
    }
  }
}