@import 'fonts.scss';

.nativeCreativeDetail {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  height: fit-content;
  position: relative;
  padding-bottom: 50px;
  .toggleButtons {
    overflow: auto;
    height: fit-content;
    z-index: 1;
    padding-top: 20px;
  }
  .nativeDetail, .nativeBannerDetail {
    height: 0px;
    overflow: hidden;
    &.show {
      height: fit-content;
    }
  }
  .buttonArea {
    span {
      margin-left: 15px;
      margin-right: 15px;
      @include base-font;
      color: $dark;
      cursor: pointer;
      &.active {
        color: $theme1;
      }
    }
  }
}

.previewPlaceholder {
  min-width: 100px;
  min-height: 100px;
  border: 1px solid $super-light;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .loadingCircle {
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 2px solid $dark;
    animation-name: roller;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    border-left-color: transparent;
  }
}

@keyframes roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}