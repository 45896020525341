@import 'fonts.scss';
@import 'colors.scss';

.noData {
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  img {
    margin-right: 20px;
  }

  div {
    font-family: PingFangTC;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: 0.3px;
    text-align: center;
    color: $light;
  }
}