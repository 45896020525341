@import 'colors.scss';

.tips {
  display: inline-block;
  width: calc(100% - 300px);
}

.tips~span.wording {
  display: none;
  margin-left: 10px;
  font-size: 10px;
  font-weight: 500;
  line-height: 1.2;
  color: $theme1;
}

.tips:hover~span.wording {
  display: inline-block;
}