@import 'colors.scss';

.label {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: $dark;
}

.currencyLabel {
  @extend .label;
  margin-right: 5px;
}


.component {
  background: $white;
  box-shadow: 5px 2px 5px 0 rgba(97, 95, 103, 0.5);
  padding: 40px 30px;

  :global {
    .form-check {
      padding-left: 0;
    }
  }
}

legend>span {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  color: $black;
  margin-left: 30px;
}


.component .price {
  width: 184px;
  border-radius: 2px;
  border: 1px solid $super-light;
  flex: unset;
}

.radioFormGroup {
  margin-bottom: -8px;
}

.radio {
  padding: 6px 15px;
}

.dailyOption,
.dailyBudgetInput {
  margin-bottom: 14px;
}

.dailyBudgetInput {
  margin-top: -10px;
}

.videoAdviewObjectiveAppend {
  margin-top: 6px;
}

.lastVideoAdviewObjective {
  margin-bottom: 30px;
  margin-top: -5px;
}

.videoAdMetricEvent {
  border-radius: 0;
  width: 184px;

  :global {
    .react-select__control {
      border-radius: 0;
    }
  }
}