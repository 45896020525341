@import 'fonts';

.audienceInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  &.row {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .audienceSizeConclusion {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-right: 0px;
    margin-bottom: 10px;
    > span {
      @include base-font;
      line-height: 1.17;
      font-size: 12px;
      color: $light;
      margin-right: 0px;
      margin-bottom: 4px;
    }
    &.row {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin-right: 60px;
      margin-bottom: 0px;
      > span {
        margin-right: 8px;
      }
    }
    
  }
  .audienceSize {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    > span:first-child {
      @include base-font;
      line-height: 1.17;
      font-size: 12px;
      color: $light;
    }
    
    .sizeContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      @include base-font;
      font-size: 14px;
      line-height: 1.57;
      color: $dark;
      margin-top: 4px;
      margin-left: 0px;

      > svg {
        margin-left: 5px;
        font-size: 14px;
        color: $light;
      }
      .loadingCircle {
        margin-left: 5px;
      }
    }

    &.row {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      .sizeContainer {
        margin-top: 0px;
        margin-left: 8px;
      }
    }
  }
}