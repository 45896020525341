@import 'fonts.scss';

.nameCell {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  .name {
    @include base-font;
    font-size: 14px;
    line-height: 1.57;
    color: $theme1;
  }

  .agencyId {
    @include base-font;
    font-size: 10px;
    font-weight: 500;
    line-height: 1.2;
    color: $dark;
    padding-top: 4px;
  }
}

tr:hover {
  .floatingEditArea {
    display: flex;
  }
}

.floatingEditArea {
  display: none;
}