@import 'colors.scss';
@import 'fonts.scss';

.formSection {
  h2 {
    @include base-font;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    color: $black;
    margin: 41px 0 16px 30px;
  }

  .formContent {
    background-color: $white;
    box-shadow: 5px 2px 5px 0 rgba(97, 95, 103, 0.5);
    padding: 40px 30px;
    padding-right: 110px;
    .formMainPart {
      min-width: 634px;
    }

    &.showHint {
      display: flex;
      padding: 0px;
      > div:first-child {
        flex-grow: 1;
        padding: 40px 30px;
      }
      > div:nth-child(2) {
        flex-shrink: 0;
        border-left: 1px solid $super-light;
      }
    }
  }
  .advancedSection {
    border-top: 3px solid $white3;
    background-color: $white;
    box-shadow: 5px 2px 5px 0 rgba(97, 95, 103, 0.5);
    padding: 0px 30px 40px 30px;
    padding-right: 110px;
    .titleContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100px;
      .title {
        @include base-font;
        color: $dark;
        font-size: 17px;
        font-weight: 500;
        line-height: 1.18;
        display: flex;
        align-items: center;
        &::before {
          width: 10px;
          height: 20px;
          margin-right: 6px;
          background: $light;
          content: '';
          display: inline-block;
        }
      }
      .triggerAdvanceSectionBtn {
        @include base-font;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.57;
        color: $black;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        color: $theme1;
        svg {
          margin-right: 10px;
        }
      }
    }
  }
}
