@import 'fonts.scss';

.reportContent {
  background-color: $white2;
  height: 100%;

  :global(.form-group.row) {
    margin-left: 0;
    margin-right: 0;
    flex: 0 0 auto;
  }

  .dimensionSelectArea {
    padding-top: 40px;
  }

  .dimensionSelectArea {
    border-top: 1px solid $super-light;
    border-bottom: 1px solid $super-light;
    background-color: $white;
    display: none;

    &.show {
      display: block;
    }
  }

  .reportTableContainer {
    flex: 1 1 auto;
  }

  .titleArea {
    color: $black;
    height: 84px;
    padding: 30px;
    box-shadow: 2px 2px 5px 0 rgba(97, 95, 103, 0.5);
    background-color: $white;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;

    .title {
      @include base-font;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.2;
    }

    .triggerAreaButtons {
      display: flex;
      align-items: center;
      margin-right: 15px;

      .triggerAreaButton {
        @include base-font;
        font-size: 14px;
        line-height: 1.2;
        margin-left: 15px;
        color: $dark;
        cursor: pointer;

        svg {
          margin-right: 10px;
        }
      }
    }
  }

  .contentArea {
    background-color: $white;
    height: calc(100% - 124px);
    display: flex;
    flex-direction: column;

    .removeFilterBtn {
      font-size: 14px;
      @include base-font;
      color: $theme2;
      margin-left: 7px;
      margin-top: 2px;
      cursor: pointer;
    }

    .filterAdder {
      display: flex;
      align-items: center;

      svg {
        font-size: 14px;
        @include base-font;
        color: $theme2;
      }

      :global(.dropdown-toggle) {
        padding: 0;
        padding-left: 6px;
      }

      :global(.dropdown-item:hover) {
        background-color: $theme5;
        color: $black;
      }

      a::after {
        display: none;
      }
    }

    .reportSearchBtnContainer {
      flex: 0 0 auto;
      padding-bottom: 30px;
      margin-bottom: 0;
    }
  }

  .reportChartArea {
    border-top: 1px solid $super-light;
    background-color: $white;
    display: none;

    &.show {
      display: block;
    }
  }

  .downloadContainer {
    position: relative;
    height: 0;

    > .downloadWording {
      position: absolute;
      top: 35px;
      right: 35px;
      color: $dark;
      cursor: pointer;

      > .downloadIcon {
        margin-right: 5px;
      }
    }
  }

  .filterArea {
    background-color: $white;
    flex: 0 0 66px;
    border-top: 1px solid $super-light;
    border-bottom: 1px solid $super-light;
    height: 66px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    :global(.search-bar) {
      width: 280px;
    }

    .tagFilter {
      cursor: pointer;
      height: 18px;
      border-radius: 12px;
      background-color: $light;
      @include base-font;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.17;
      color: $white;
      padding: 0 10px;
      display: flex;
      align-items: center;
      margin: 0 5px 5px 0;

      &.selected {
        background-color: $theme1;
      }
    }
  }

  :global {
    .react-select--is-disabled {
      div {
        background-color: $white;
        color: $black;
      }

      .react-select__indicators {
        display: none;
      }
    }
  }
}
