@import 'colors.scss';

.ppsCreativeDetailContainer {
  overflow: auto;
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  iframe {
    width: 100%;
    height: 100%;
    background-color: $super-light;
  }
  &.isMobile {
    iframe {
      width: 320px;
      height: 568px;
    }
  }
}