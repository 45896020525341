@import 'colors.scss';

:global {
  .input-group-text {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: $dark;
    background-color: $white;
    border-radius: 2px;
    border: 1px solid $super-light;
  }

}