@import 'fonts.scss';

.campaignGroupDetailContainer {
  background-color: $white2;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 1px;
  :global(.dropdown-menu) {
    transform: translate3d(-25px, 25px, 0px) !important;
    min-width: auto;
  }
  .titleBottomLine {
    width: 100%;
    height: 2px;
    border: 1px solid $super-light;
    background-color: $white;
  }
  .detail {
    .topArea {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      height: 52px;
      .buttonArea {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-right: 30px;
        flex-shrink: 0;
        a {
          cursor: pointer;
          margin-left: 30px;
          @include base-font;
          font-size: 14px;
          font-weight: 500;
          line-height: 1;
          color: $theme1;
        }
        a:hover {
          color: $theme2;
        }
      }
    }
    box-shadow: 2px 2px 5px 0 rgba(97, 95, 103, 0.5);
    background-color: $white;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
  }
  .other {
    overflow: hidden;
    box-shadow: 2px 2px 5px 0 rgba(97, 95, 103, 0.5);
    margin-top: 28px;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    background-color: $white2;
  }
}