@mixin capsule {
  border-width: 1px;
  border-style: solid;
  border-color: #e5e5e5;
  border-radius: 8px;
}

.reportHeader {
  display: block;
  page-break-before: always;
  page-break-inside: avoid;
  page-break-after: avoid;

  .headerFixContainer {
    height: 300px;

    .topBarContainer {
      height: 90px;
      display: flex;

      .topBarSection {
        width: 50%;
        display: flex;
        align-items: center;
        padding: 20px 40px 0px;

        .logo {
          width: 35%;
          margin-right: auto;
        }
        .confidential {
          padding: 8px;
          margin-left: auto;
          border-radius: 8px;
          background-color: #e03854;
          color: #ffffff;
          font-weight: 800;
        }
      }
    }
    .headerTitle {
      height: 210px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-image: url(../../../assets/report_header_title.png);
      background-size: cover;

      .title {
        padding: 5px 0px;
        color: #ffffff;
        text-align: center;
        font-family: 'Maison Neue', sans-serif;
        font-weight: 700;
        font-size: 35px;
      }
    }
  }
  .settingContainer {
    background-color: #f6f6f6;
    display: flex;
    justify-content: space-between;
    padding: 10px 60px 20px;
    flex-wrap: wrap;

    .setting {
      height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .title {
        color: #838383;
        font-size: 16px;
        padding: 5px 0px;
      }
      .content {
        background-color: #ffffff;
        display: flex;
        justify-content: space-between;
        min-width: 240px;
        padding: 15px 20px;
        color: #545454;
        font-size: 18px;
        font-weight: 700;
        @include capsule;
      }
    }
  }
}

.gridHalf {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
}

.block {
  padding: 10px 20px;
  margin: 20px 0px;
  page-break-inside: avoid;
  @include capsule;

  @mixin optimize-large {
    font-size: 20px;
    font-weight: 800;
    color: #545454;
  }

  @mixin optimize-small {
    font-size: 14px;
    font-weight: 800;
    color: #545454;
  }

  > .title {
    padding: 15px 0px 10px;
    font-family: 'Maison Neue', sans-serif;
    font-size: 20px;
    font-weight: 700;
    color: #545454;
  }
  > .chartLabels {
    padding: 10px 0px 5px;
    font-size: 14px;
    color: #a4a4a4;
    display: flex;
    justify-content: space-between;
  }
  > .chartLegends {
    padding: 10px 0px 5px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .legend {
      padding: 5px 10px;
      font-size: 14px;
      color: #838383;
      display: flex;
      align-items: center;

      > .classification {
        width: 12px;
        height: 12px;
        margin-right: 10px;
      }
    }
  }
  .impressions {
    margin: 10px 20px;
    @include capsule;

    .row {
      display: flex;
      @mixin impression-element {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 15px 25px;
        color: #838383;

        span {
          margin-left: auto;
        }
      }

      .actualImpressionTitle {
        @include impression-element;
        background-color: #f6f6f6;
        border-right: 1px solid #e5e5e5;
        font-size: 16px;
      }        
      .actualImpressionContent {
        @include impression-element;
        @include optimize-large;
      }
      .targetImpression {
        @include impression-element;
        padding: 10px 25px;
        border-top: 1px solid #e5e5e5;
        font-size: 14px;
      }
    }
  }
  .statistics {
    display: flex;
    justify-content: center;
    margin: 10px 20px;
    flex-wrap: wrap;
    
    .element {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5px 30px;
      border-right: 1px solid #e5e5e5;

      :last-child {
        border-right: none;
      }
      .title {
        color: #838383;
        font-size: 16px;
        padding: 5px 0px;
      }
      .content {
        @include optimize-large;
      }
    }
  }
  .table {
    flex-direction: column;
    margin-top: 10px;

    .row {
      display: flex;
      border-top: 1px solid #e5e5e5;
    }
    .title {
      background-color: #f6f6f6;
      border: none;
    }
    .element {
      width: 100%;
      min-height: 50px;
      padding: 10px 0px;
      text-align: center;
      font-size: 14px;
      color: #838383;
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow-wrap: anywhere;
    }
    .elementShrunkOne {
      font-size: 80%;
    }
    .elementShrunkTwo {
      font-size: 75%;
    }
    .withHint {
      flex-direction: row;
      padding: 0px;
    }
    .optimal {
      @include optimize-small;
    }
    .row:nth-of-type(2) {
      border-top: none;
    }
    .title:first-child .element:first-child {
      background-color: #279F00;
      color: #ffffff;
    }
  }
  .impacts {
    display: grid;

    .impact {
      @include capsule;
      display: flex;
      justify-content: space-between;
      margin: 10px 10px;

      .title {
        width: 50%;
        min-width: 200px;
        max-width: 200px;
        background-color: #f6f6f6;
        border-right: 1px solid #e5e5e5;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        padding: 20px 20px;
        color: #838383;
        display: flex;
        flex-direction: column;
        align-items: center;

        .growth {
          border-width: 1px;
          border-style: solid;
          border-color: #e5e5e5;
          border-radius: 8px;
          padding: 5px 10px;
          color: #ffffff;
          margin: 5px 0px;
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        .positive {
          background-color: #279F00;

          .triangle {
            margin: 5px 5px 5px 10px;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 12px solid #ffffff;
          }            
        }                        
        .negative {
          background-color: #e03854;

          .triangle {
            margin: 5px 5px 5px 10px;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 12px solid #ffffff;
          }
        }
      }
      .contents {
        width: 100%;
        display: flex;

        .content {
          width: inherit;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 20px 20px 20px 0px;
          font-size: 14px;
          color: #838383;

          span {
            margin-left: auto;
          }
          .value {
            @include optimize-large;
            padding: 5px 0px;
          }
        }
        .content:not(:last-child) {
          border-right: 1px solid #e5e5e5;
        }
      }
    }
  }
  .mainImpacts {
    grid-template-columns: 1fr;
  }
  .subImpacts {
    grid-template-columns: 1fr 1fr;
  }
  .usersRegion {
    width: 95%;
    flex-direction: column;

    .usersLabel {
      display: flex;
      align-items: center;

      .label {
        margin-left: auto;
        font-size: 16px;
        color: #a4a4a4;
      }
    }
    .regionData {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0px;

      .region {
        width: 30%;
        font-size: 16px;
        color: #838383;
        overflow-wrap: break-word;
      }
      .percentageBar {
        width: 45%;

        .progressBackground {
          height: 5px;
          border-radius: 8px;
          background-color: #d8d8d8;

          .progress {
            height: 100%;
            border-radius: 8px;
          }
          .usersProgressNew {
            background-color: #279F00;
          }              
          .usersProgressLapse {
            background-color: #fb8c00;
          }              
          .usersProgressExisting {
            background-color: #838388;
          }              
        }
      }
      .users {
        width: 20%;
        text-align: end;
        font-size: 16px;
        font-weight: 500;
        color: #545454;
        overflow-wrap: break-word;
      }
    }
    .regionData:not(:last-child) {
      border-bottom: 1px solid #e5e5e5;
    }
  }
}

.notes {
  padding: 5px 10px;
  font-size: 14px;
  color: #838383;
  margin: 15px 0px;
  page-break-inside: avoid;

  .row {
    display: flex;
    padding-top: 2px;

    .lineTitle {
      font-weight: 700;
      padding: 0px 5px;
    }
    .title {
      font-weight: 700;
      padding-right: 2px;
    }
    .content {
      color: #a4a4a4;
    }
    .benchmark {
      display: grid;
      grid-template-columns: auto 1fr 1fr 1fr;
      color: #a4a4a4;
    }
    .benchmarkTitle {
      display: flex;
      justify-content: space-between;
      width: 140px;
    }
    .benchmarkTitle::after {
      content: ':';
    }
    .benchmarkContent {
      padding: 0px 10px;
      border-right: 1px solid #e5e5e5;
    }
    .benchmarkContent:last-child {
      border-right: none;
    }
  }
}