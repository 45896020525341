@import 'colors.scss';
@import 'fonts.scss';
@import 'components.scss';

.finalReportPageContent {
  background-color: $white2;
  height: 100%;
  display: flex;
  flex-direction: column;
  @include tabs;

  .titleArea {
    color: $black;
    height: 84px;
    padding: 30px;
    box-shadow: 2px 2px 5px 0 rgba(97, 95, 103, 0.5);
    background-color: $white;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;

    .title {
      color: $black;
      @include base-font;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.2;
    }

    .openBtn {
      width: auto;
      height: 100%;
      padding: 15px;
      background-color: $theme1;
      display: flex;
      align-items: center;
      margin-left: auto;
      color: $white;
      border-radius: 5px;
    }

    .openBtn:hover {
      opacity: 0.7;
    }
  }

  .contentArea {
    background-color: $white;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    :global(.nav) {
      flex-shrink: 0;
      padding-top: 30px;
    }
    :global(.text-danger) {
      display: block;
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.17;
      letter-spacing: normal;
      margin-top: 2px;
      width: 100%;
    }
    .estimatorContainer {
      flex-grow: 1;
      @include tab-form-content;
      .fieldContent {
        overflow: visible;
      }
      .fieldContent:not(:first-child) {
        margin-top: 40px;
      }
      .formContent {
        overflow: auto;
        padding: 40px 0px 40px 30px;
      }
      .campaignSettings {
        width: 100%;
        min-width: max-content;
        display: flex;
        flex-direction: row;
        margin: 10px 0px;

        .content {
          display: flex;
          flex-direction: column;
          width: 180px;
          margin: 0px 20px;
          
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          letter-spacing: normal;

          .label {
            margin-bottom: 10px;
            font-size: 12px;
            color: $light;
          }

          .data {
            font-size: 14px;
            line-height: 1.57;
            margin-top: 1px;
            padding: 6px 0px;
            white-space: pre-line;
          }
        }

        .removeBtn {
          height: fit-content;
          align-self: center;
          margin-left: auto;
          margin-right: 40px;
          margin-left: auto;
        }
      }
      background-color: $white3;
      padding-bottom: 56px;
      height: 100%;

      .buttonArea {
        button {
          margin-left: 20px;
        }
        button:first-child {
          margin-left: 30px;
        }
    
        padding-top: 30px;
      }
    }

    .resultContent {
      width: max-content;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      
      .element {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 180px;
        margin: 0px 20px;

        .label {
          margin-bottom: 10px;
        }

        .data {
          font-size: 14px;
          line-height: 1.57;
          margin-top: 1px;
          padding: 6px 0px;
          white-space: pre-line;
        }
      }

      .firstFormElement {
        width: 620px;
        margin: 30px 20px;
      }
    }

    .reachEstimatorList {
      flex-grow: 1;
      background-color: $white2;
      th, td {
        vertical-align: middle;
        padding-right: 15px;
        padding-left: 10px;
        user-select: none;
        width: 25%;
      }

      th:first-child, td:first-child {
        padding-left: 64px;
      }

      thead > tr > th {
        border-top: 1px solid $super-light;
        border-bottom: 1px solid $super-light;
      }

      thead > tr > th:nth-child(1) {
        z-index: 1;
      }

      .tips {
        display: flex;
        flex-direction: column;
        width: auto;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        color: $theme1;
        padding: 10px 20px 50px;
      }
    }

    .tableReach {
      tbody > tr:nth-child(1) {
        :global(td) {
          font-weight: 700;
          font-style: italic;
        }
      }
    }
  }
}