.creditPayModal {
  :global(.row) {
    margin-left: 0;
    margin-right: 0;
  }
  :global(.col-sm-9) > label {
    line-height: 36px;
  }
  .payContent {
    padding-top: 30px;
  }
}
.emptyContent {
  height: 100%;
  display: flex;
  align-items: center;
}
