@import 'colors.scss';

.htmlCreativeDetailContainer {
  overflow: auto;
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  iframe {
    width: 100%;
    height: 100%;
    border: 1px solid $super-light;
  }
}