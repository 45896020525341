@import 'colors.scss';

.toggleButtons>label {
  margin-right: unset;
  cursor: pointer;
  border: solid 1px #98969d;
  padding: 10px 27.5px;
  font-weight: 500;

  &:global {
    &.active:not(:disabled):not(.disabled) {
      background-color: $theme1;
    }

    &.focus {
      box-shadow: none;
      border: solid 1px #98969d;
    }

    &.btn-secondary:not(:disabled):not(.active) {

      &:hover,
      &:active {
        background-color: inherit;
        color: $dark;
        border: solid 1px #98969d;
      }
    }

  }
}