@import 'fonts';

.approvalData {
  @mixin gird-template($count) {
    grid-template-columns: auto repeat($count, 80px);
  }
  display: grid;
  &.count1 {
    grid-template-columns: 80px;
  }
  &.count2 {
    @include gird-template(1);
  }
  &.count3 {
    @include gird-template(2);
  }
  &.count4 {
    @include gird-template(3);
  }
  grid-column-gap: 10px;
  .title {
    grid-column: 1 / 2;
    white-space: nowrap;
  }

  > span {
    text-align: center;
  }
}