@import "../../fonts.scss";
@import "../../colors.scss";
@import "../../components.scss";

.app-header {
  margin: 0px;
  width: 100%;
  height: 50px;
  position: fixed;
  display: flex;
  flex-direction: row;
  z-index: 6;
  color: $white;
  background-color: $theme1;

  .hidden {
    display: none;
  }

  ul {
    @include floating-menu($white);
    padding: 0;
    color: $black;
    display: block;
    min-width: 170px;
    position: absolute;
    list-style-type: none;
    list-style-position: unset;
    margin-left: -15px;

    li {
      @include body-font;
      height: 42px;
      cursor: pointer;
      margin: auto 0px;
      line-height: 42px;
      padding-left: 12px;
    }

    li:hover {
      background-color: $white2;
    }
  }

  .toggle {
    margin: auto auto auto 0px;
    font-size: 20px;
    cursor: pointer;
    color: $theme2;
    &:hover {
      color: $theme3;
    }
  }

  .logo {
    margin: auto 20px;

    img {
      height: 34px;
    }
  }

  .account-info {
    @include button-white;
    padding-left: 15px;
    line-height: 50px;
    color: $white;
    .menu-title {
      display: flex;
      cursor: pointer;

      .arrow {
        @include arrow-down($white);
        margin: auto 15px;
      }
    }

    &:hover {
      background-color: $theme2;
    }
  }
}
