@import 'components';

.agencyFrom {
  @include tab-form-content;
  .fieldContent {
    overflow: visible;
  }
}

.autoHeight {
  > div {
    height: auto !important;
  }
}

.autoWithSelect {
  width: auto;
}

.bidPriceMinSetupSwitch {
  margin-top: 8px;
}

.subTitle {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  label {
    text-align: left !important;
    padding-left: 0 !important;
  }
  span {
    margin-right: 10px;
  }
}

.manualForm {
  :global(.input-group) {
    width: 150px;
  }
  label {
    text-align: left !important;
    padding-left: 0 !important;
  }
}
.formLabel {
  text-align: left;
  color: $dark;
  height: 35px;
  line-height: 35px;
}
.row {
  display: flex;
  button {
    margin-left: 20px;
    @include base-font;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    color: $dark;
    height: 35px;
    margin-left: 20px;
  }
}
