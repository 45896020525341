@import 'fonts.scss';
@import 'components.scss';

.creditList {
  $topAreaHeight: 77px;
  min-height: 100%;
  background-color: $white3;
  display: flex;
  flex-direction: column;
  .contentArea {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    @include tabs;
    :global(.nav) {
      padding-top: 30px;
      height: 72px;
    }
    > div:last-child {
      flex-grow: 1;
    }
  }
  td {
    vertical-align: middle;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  thead th,
  td {
    &.editBtns {
      width: 0;
      min-width: 0;
      max-width: 0;
      padding: 0px;
    }
  }
  th:first-child,
  td:first-child {
    padding-left: 60px;
  }
  .floatingEditArea {
    display: none;
  }
  .nameCell {
    .controlName {
      @include base-font;
      width: 172px;
      font-size: 14px;
      line-height: 1.57;
      color: $theme1;
    }
    .controlId {
      @include base-font;
      font-size: 10px;
      font-weight: 500;
      line-height: 1.2;
      color: $dark;
      padding: 4px 0px;
    }
  }
  .productGroupAgencies {
    white-space: pre-line;
  }
  tr:hover {
    .floatingEditArea {
      display: flex;
    }
    td {
      background-color: $white2;
    }
  }
  .titleArea {
    color: $black;
    height: 84px;
    padding: 30px;
    box-shadow: 2px 2px 5px 0 rgba(97, 95, 103, 0.5);
    background-color: $white;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;

    .title {
      @include base-font;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.2;
    }
  }
  .filterArea {
    a {
      margin-left: 30px;
    }
    width: 100%;
    flex-shrink: 0;
    border-bottom: 1px solid #dee2e6;
    display: flex;
    align-items: center;
    height: $topAreaHeight;
    background-color: $white;
    position: relative;
    :global(.search-bar) {
      width: 370px;
      margin: 0px;
      position: absolute;
      right: 30px;
    }
    .createBtn {
      color: $white;
    }
  }
}

.tableContainer {
  width: 100%;
  max-height: 250px;
  overflow: auto;
  border: 1px solid $super-light;
  :global(.table) {
    margin-bottom: 0;
  }
}
