@import 'fonts.scss';

.overlay {
  max-width: 280px;
  user-select: text;
  @include base-font;
  color: $white;
  font-size: 12px;
  border-radius: 2px;
  padding: 10px;
  opacity: 0.9;
  background-color: $black;
  border-radius: 0.25rem;
}