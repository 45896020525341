@import 'fonts.scss';

.logoInput {
  width: fit-content;
  > div {
    width: 150px;
    height: 150px;
    box-sizing: content-box;
    border: dashed 1px $super-light;
    background-color: $white;
  }

  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &.showSolidBorder {
    > div {
      border: 1px solid $super-light;
    }
  }

  &.hideBorder {
    > div {
      border: none;
    }
  }
}
