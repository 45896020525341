@import '../../colors.scss';

.errorPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  min-height: 100%;

  .buttons {
    margin-top: 15px;
    :global(.btn) {
      margin: 15px;
    }
  }

  .label {
    width: 507px;
    font-family: Avenir;
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: center;
    color: $theme2;
    span {
      color: $theme1;
    }
  }
}

.errorPage + div {
  display: none;
}