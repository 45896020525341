@import 'colors.scss';
@import 'fonts.scss';

.selectComponentContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
  width: 400px;
  background-color: white;
  border: solid 1px $super-light;

  .selectItems {
    flex-grow: 1;
    min-height: 250px;
    overflow: auto;
  }

  .placeholder {
    @include base-font;
    flex-grow: 1;
    min-height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.72;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.57;
    color: $dark;
  }

  :global(.search-bar) {
    margin: 0 -1px;

    &:not(:hover):not(:focus):not(:global(.focused)) {
      border-left: none;
      border-right: none;
      border-top: none;
    }

  }

  .classifyCount {
    @include base-font;
    box-sizing: content-box;
    height: 22px;
    border-top: solid 1px $super-light;
    padding: 4px 0px 4px 11.7px;
    height: 14px;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.17;
    color: $dark;
  }

  .singleSelectTip {
    @include base-font;
    padding: 10px 0px 10px 12px;
    width: 100%;
    height: 34px;
    background-color: $light;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.17;
    color: $white;
  }
}