@import 'colors.scss';

.info {
  color: $super-light;
  width: 12px;
  height: 14px;
  font-family: FontAwesome;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-left: 9px;
  cursor: pointer;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  margin-left: -30px;

  &:hover {
    color: $theme1;
  }
}