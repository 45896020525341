@import 'fonts.scss';

.videoCreativeDetailContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .videoCreativeDetail {
    max-width: 600px;
    max-height: 400px;
    
    .thirdVideoDetail {
      width: 600px;
      height: 300px;
    }
  }
}