$theme1: #035F1D;
$theme2: #257226;
$theme3: #008C15;
$theme4: #2C5234;
$theme5: #279F00;
$theme6: #A7C7B0;
$primary1: #bd7ab3;
$primary2: #D48BC8;
$primary3: #E2ACD7;
$primary4: #924E8C;
$primary5: #EEDAEA;
$black: #242328;
$dark: #615f67;
$light: #a7a6ac;
$super-light: #d8d8d8;
$danger: #ce555c;
$light-danger: #ffdfe0;
$success: #76b793;
$light-success: #e8f5eb;
$deep-warning: #ee75ee;
$warning: #ffa953;
$light-warning: #fff2e4;
$info: #e2acd7da;
$light-info: #EEDAEA;
$white: #ffffff;
$white2: #f7f7f7;
$white3: #f4f4f4;
$light-pink: #f1f1f1;