@import './fonts.scss';

@mixin badge {
  @include medium;
  @include caption-font;
  height: 22px;
  border-radius: 11px;
  padding: 4px 10px;
  text-align: center;
}

@mixin base-arrow {
  width: 0;
  height: 0;
  border-style: solid;
}

@mixin arrow-up($color) {
  @include base-arrow;
  border-width: 0px 4px 4px 4px;
  border-color: transparent transparent $color transparent;
}

@mixin arrow-down($color) {
  @include base-arrow;
  border-width: 4px 4px 0 4px;
  border-color: $color transparent transparent transparent;
}

@mixin floating-menu($color) {
  position: absolute;
  z-index: 100;
  background-color: $color;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.3);
}

@mixin panel($color: true) {
  background-color: $color;
  margin-bottom: 5px;
  box-shadow: 0 2px 5px 0 rgba(97, 95, 103, 0.5);
}

@mixin main-container {
  overflow: auto;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-content: stretch;
  background-color: $white2;
  min-height: 100%;
  height: 1px;
}

@mixin list-container {
  background-color: $white2;
  align-self: stretch;
  flex: 1 1 auto;
}

@mixin tabs {
  :global {
    .nav {
      padding-left: 30px;
      background-color: $white;
      border-bottom: solid 1px #979797;
      flex-wrap: nowrap;
      .nav-link {
        color: $dark;
        border: 1px solid transparent;
        margin-bottom: -1px;
        padding: 13px 25px;
      }

      :global(.nav-item.active),
      :global(.nav-link.active) {
        border-radius: 2px;
        border-top-color: #979797;
        border-left-color: #979797;
        border-right-color: #979797;
        border-bottom-color: transparent;
        background-color: $white3;
        color: $theme1;
        border-bottom: 1px solid transparent !important;
      }

      :global(.nav-link):not(:global(.active)):hover {
        background-color: $white2;
        border-color: transparent;
        border-bottom: 1px solid #979797 !important;
      }

      :global(.nav-item.disabled),
      :global(.nav-link.disabled) {
        color: $light;
      }
    }
  }
}

@mixin createButton {
  @include base-font;
  height: 34px;
  border-radius: 2px;
  padding: 10px 30px;
  display: flex;
  align-items: center;
}

@mixin tab-form-content() {
  fieldset {
    margin-top: 40px;
    padding-left: 0;
    padding-right: 0;
  }
  legend > span {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    color: $black;
    margin-left: 30px;
  }

  :local(.fieldContent) {
    overflow: auto;
    background-color: $white;
    box-shadow: 5px 2px 5px 0 rgba(97, 95, 103, 0.5);
    padding: 40px 110px 40px 30px;
    :global {
      .form-check {
        padding-left: 0;
      }
    }
  }
}
