@import 'fonts.scss';

.container {
  :global {
    width: 100%;
    .react-tagsinput {
      background-color: initial;
      border: none;
      padding-left: 30px;

      > span {
        margin-top: 0;
      }
    }

    :local(.listEditBar) {
      display: flex;
      align-items: center;
    }

    :local(.listEditBar).nav {
      width: 100%;
      margin-top: 0;
      background-color: $theme1;
      height: 100%;
      padding: 20px 0;
      .nav-link {
        padding: 13px 25px;
        &.dropdown-toggle::after {
          content: '\f107';
          font-family: fontAwesome;
          border: none;
          vertical-align: initial;
          margin-left: 6px;
        }

        &:not(.active) {
          color: $white;
          background-color: initial;
          border-bottom: 0;

          &:hover {
            background-color: initial;
            border-bottom: 0 !important;
          }
        }
        &.disabled {
          color: $light;
          background-color: initial;
        }
      }
      .react-tagsinput {
        min-height: auto;
        .react-tagsinput-tag {
          margin: 0;
        }
      }
      .dropdown-menu.show {
        transform: translate3d(25px, 33px, 0px) !important;
        .dropdown-item:active {
          background-color: $white2;
          color: $black;
        }
      }
    }
  }
}