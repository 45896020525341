

.previewTrackingCode {
  color: #676767 !important;
  cursor: default;
}

:global(textarea.form-control).tmpOriginTrackingCode {
  height: 100px;
}

.select {
  width: auto;
}