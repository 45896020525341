@import 'fonts.scss';

.stepPage {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  .sideBar {
    height: 100%;
    min-width: 200px;
    max-width: 200px;
    background-color: $light-pink;
    overflow: auto;
    display: flex;
    flex-direction: column;
    .cacelBtn {
      width: fit-content;
      margin-top: 30px;
      margin-left: auto;
      margin-right: auto;
      display: block;
      @include base-font;
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
      color: $dark;
      height: 34px;
      width: 116px;
    }
    .steps {
      flex-grow: 1;
      padding-bottom: 30px;
    }
    .step {
      margin-top: 62px;
      min-height: 22px;
      @include base-font;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.57;
      color: $black;
      position: relative;
      margin-left: 45px;

      :first {
        margin-top: 50px;
      }

      .mainStep {
        cursor: pointer;
      }

      &.active {
        color: $theme1
      }

      &.locked {
        pointer-events: none;
      }

      .icon {
        position: absolute;
        left: -30px;
        top: 3px;
        &.locked {
          color: $dark;
        }
  
        &.finished {
          color: $success;
        }
      }
    }
    .subStep {
      color: $dark;
      margin-top: 20px;
      margin-bottom: 20px;
      cursor: pointer;
      &.active {
        color: $theme1
      }
    }
    .sidebarFooter {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .stepContent {
    width: calc(100% - 200px);
  }
}