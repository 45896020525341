@import 'fonts.scss';
@import 'components.scss';

.campaignGroupList {
  $topAreaHeight: 77px;
  min-height: 100%;
  background-color: $white3;
  display: flex;
  flex-direction: column;
  .contentArea {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .listConditionArea {
      flex-shrink: 0;
      padding-top: 30px;
      background-color: $white;
      @include tabs;
      display: flex;
      flex-direction: row;
      position: relative;
      :global(.nav) {
        width: 100%;
      }
      :global(.nav-link) {
        display: flex;
        align-items: center;
      }
      .draftHint {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-left: 6px;
        background-color: $theme1;
      }
      .metricsOptionsContainer {
        position: absolute;
        top: 17px;
        right: 30px;
        .metricsOptions {
          width: 200px;
          z-index: 10;
        }
      }
    }
  }
  th {
    white-space: nowrap;
  }
  td {
    vertical-align: middle;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  thead th,
  td {
    &.editBtns {
      width: 0;
      min-width: 0;
      max-width: 0;
      padding: 0px;
    }
  }
  th:first-child,
  td:first-child {
    padding-left: 20px;
  }
  .floatingEditArea {
    display: none;
  }
  .selectCheckBox {
    min-width: 48px;
    height: auto;
    input {
      display: none;
    }
    input + label {
      margin-right: 0;
    }
    input:checked + label::before,
    input:indeterminate + label::before {
      background-color: $theme1;
      border: 1px solid $theme1;
    }
    input:indeterminate + label::after {
      content: '';
      position: absolute;
      left: 2px;
      top: 13px;
      width: 1px;
      height: 1px;
      box-shadow: 1px 0 0 $white, 2px 0 0 $white, 3px 0 0 $white,
        4px 0 0 $white, 5px 0 0 $white, 6px 0 0 $white, 7px 0 0 $white,
        8px 0 0 $white;
    }
  }
  .nameHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .nameCell {
    display: flex;
    flex-direction: row;
    align-items: center;
    .name {
      @include base-font;
      width: 172px;
      font-size: 14px;
      line-height: 1.57;
      color: $theme1;
    }
    .id {
      @include base-font;
      font-size: 10px;
      font-weight: 500;
      line-height: 1.2;
      color: $dark;
      padding: 4px 0px;
    }
    .draftsInfo {
      width: max-content;
      padding: 2px 10px;
      font-size: 12px;
      line-height: 1.17;
      color: $theme1;
      border-radius: 12px;
      border: solid 1px $theme1;
      margin: 5px 0px;
    }
  }
  .resultCell {
    position: relative;
    .value {
      color: $dark;
    }
    .objective {
      font-size: 10px;
      color: $light;
      position: absolute;
      right: 0;
    }
  }
  tr:hover {
    .floatingEditArea {
      display: flex;
    }
    td {
      background-color: $white2;
    }
  }
  .titleBar {
    width: 100%;
    height: $topAreaHeight;
    flex-shrink: 0;
    border-bottom: 1px solid #dee2e6;
    position: relative;
  }
  .filterArea {
    display: flex;
    align-items: center;
    height: 100%;
    background-color: $white;
    :global(.search-bar) {
      width: 370px;
      margin: 0px;
      position: absolute;
      right: 30px;
    }
    .createBtn {
      color: $white;
    }
  }
  .stateCell {
    @include base-font;
    transform: scale(0.95);
    font-size: 12px;
    font-weight: 500;
    line-height: 1.17;
    color: $white;
    height: 22px;
    width: max-content;
    border-radius: 11px;
    background-color: $theme1;
    line-height: 22px;
    padding: 0px 10px;
    box-sizing: content-box;
    &.budgetRemain,
    &.deleted {
      background-color: $danger;
    }
    &.deactive {
      background-color: $black;
    }
    &.stopping {
      color: $theme4;
      border: 1px solid $theme4;
      background-color: $white;
    }
  }
  .groupId {
    min-width: 250px;
    max-width: 360px;
  }
  :global(.summaryRow) {
    .groupId {
      div {
        padding-left: 48px;
      }
    }
  }
  .app_custom_event,
  .app_custom_event_fb_mobile_activate_app,
  .app_custom_event_fb_mobile_add_payment_info,
  .app_custom_event_fb_mobile_add_to_cart,
  .app_custom_event_fb_mobile_add_to_wishlist,
  .app_custom_event_fb_mobile_complete_registration,
  .app_custom_event_fb_mobile_content_view,
  .app_custom_event_fb_mobile_initiated_checkout,
  .app_custom_event_fb_mobile_purchase,
  .app_custom_event_fb_mobile_search,
  .app_custom_event_other,
  .comment,
  .like,
  .link_click,
  .post,
  .post_engagement,
  .post_reaction,
  .budget,
  .channel,
  .results,
  .uu,
  .impres,
  .viewable,
  .clicks,
  .cpc,
  .ctr,
  .vctr,
  .convs,
  .cpa,
  .cvr,
  .videoPlayActions,
  .videoWatched2s,
  .videoWatched6s,
  .videoViewsP25,
  .videoViewsP50,
  .videoViewsP75,
  .videoViewsP100,
  .profileVisits,
  .likes,
  .comments,
  .shares,
  .follows,
  .clicksOnMusicDisc,
  .spent {
    white-space: nowrap;
  }
  .app_custom_event,
  .app_custom_event_fb_mobile_activate_app,
  .app_custom_event_fb_mobile_add_payment_info,
  .app_custom_event_fb_mobile_add_to_cart,
  .app_custom_event_fb_mobile_add_to_wishlist,
  .app_custom_event_fb_mobile_complete_registration,
  .app_custom_event_fb_mobile_content_view,
  .app_custom_event_fb_mobile_initiated_checkout,
  .app_custom_event_fb_mobile_purchase,
  .app_custom_event_fb_mobile_search,
  .app_custom_event_other,
  .comment,
  .like,
  .link_click,
  .post,
  .post_engagement,
  .post_reaction,
  .results,
  .uu,
  .impres,
  .viewable,
  .clicks,
  .cpc,
  .ctr,
  .vctr,
  .convs,
  .cpa,
  .cvr,
  .videoPlayActions,
  .videoWatched2s,
  .videoWatched6s,
  .videoViewsP25,
  .videoViewsP50,
  .videoViewsP75,
  .videoViewsP100,
  .profileVisits,
  .likes,
  .comments,
  .shares,
  .follows,
  .clicksOnMusicDisc,
  .spent {
    text-align: right;
  }
  .createCampaignGroup {
    margin-left: 30px;
    display: inline-block;
  }
  .tipContainer {
    position: relative;
  }
  .tipContainer.block {
    &:hover {
      cursor: not-allowed;
      .tooltip {
        display: block;
      }
    }
    a {
      pointer-events: none;
      background-color: $theme6;
      border-color: $theme6;
    }
  }
  .tooltip {
    display: none;
    position: absolute;
    left: 65px;
    top: 20px;
    z-index: 2;
    height: 15px;
    font-size: 12px;
    font-weight: 500;
    @include base-font;
    line-height: 1.17;
    color: $dark;
    background-color: $white;
    padding: 0 5px;
    white-space: nowrap;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: $black;
    border-radius: 0.25rem;
    top: unset;
    bottom: -25px;
    height: auto;
  }
}

.tableContainer {
  width: 100%;
  max-height: 250px;
  overflow: auto;
  border: 1px solid $super-light;
  :global(.table) {
    margin-bottom: 0;
  }
}
