@mixin font-title {
  font-family: 'Maison Neue', sans-serif;
  font-weight: 900;
}

@mixin pdf {
  @media print {
    @content
  }
}

.reportMain {
  padding: 10px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  background-color: #ffffff;
  width: 1024px;
  margin: auto;

  .reportContent {
    padding: 0px 60px;
    display: block;
    page-break-before: avoid;

    > .title {
      @include font-title;
      padding: 20px 0px 5px;
      font-size: 28px;
      color: #363636;
    }
    > .subtitle {
      @include font-title;
      padding: 10px 30px;
      font-size: 24px;
      color: #ffffff;
      margin-bottom: 10px;
    }
    .adsImpactBg {
      background-color: #ef6c00;
    }
    .adsPerformanceBg {
      background-color: #0086d0;
    }
    .deepDivePlatformTitle {
      @include font-title;
      padding: 20px 0px 5px;
      font-size: 24px;
      color: #545454;
      margin-top: 10px;
      
      > img {
        height: 36px;
        padding: 0px 10px;
      }
    }
    .hint {
      font-size: 14px;
      font-weight: 700;
      color: #363636;
    }
    .rowDirection {
      display: flex;
      justify-content: center;
      align-items: center;

      .platformLogo_ordinary {
        width: 80%;
      }
      .platformLogo_small {
        width: 30%;
      }
    }
  }
  .timestamp {
    // display: none;
    // position: fixed;
    position: static;
    bottom: 0;
    margin: 60px 0px 20px;
    // margin: 20px 0px;
    font-size: 14px;
    color: #a4a4a4;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include pdf {
      // position: fixed;
      position: static;
      bottom: 0;
      margin: 60px 0px 20px;
      // margin: 20px 0px;
      font-size: 14px;
      color: #a4a4a4;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

@media print {
  @page {
    /* size: 28cm 91.6cm; */
    size: 28cm 240rem;
    margin: 0;
  }
}
