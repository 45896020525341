@import 'colors.scss';
@import 'fonts.scss';

.conversionFormContainer {
  height: 100%;
  background-color: $white2;

  .title  {
    background-color: $white;
    height: 120px;
    @include base-font;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    color: $black;
    padding: 30px 30px 0px 30px;
  }

  .titleBottomLine {
    width: 100%;
    height: 2px;
    border: 1px solid $super-light;
    background-color: $white;
  }

  .formArea {
    background-color: $white;
    box-shadow: 2px 2px 5px 0 rgba(97, 95, 103, 0.5);
    margin-top: 30px;
    margin-bottom: 60px;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .buttonArea {
    button {
      margin-left: 20px;
    }
    button:first-child {
      margin-left: 30px;
    }

    padding-bottom: 60px;
  }

  :global(.col-sm-3) {
    label {
      @include base-font;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.57;
      color: $black;
    }
  }

  :global(.col-sm-9) {
    @include base-font;
    font-size: 14px;
    line-height: 1.57;
    color: $black;
    
    > input, .advId, .conversionType {
      max-width: 424px;
      min-width: 424px;
    }
  }

  .conversionDuration {
    :global(.input-group) {
      max-width: 424px;
      min-width: 424px;
    }
    span.durationHint {
      display: block;
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.17;
      letter-spacing: normal;
      color: #98969d;
      margin-top: 2px;
      width: 100%;
    }
  }
}