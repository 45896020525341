@import 'colors.scss';

.fieldContent {
  display: grid;
  grid-template-columns: auto 1fr;
}

.fieldComponent {
  grid-column: 1 / 2;
  :global(.col-form-label) {
    text-align: left;
  }
}

.postText {
  padding: 7px 15px 7px 10px;
  font-family: NotoSansSC, 'Noto Sans SC';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 22px;
  letter-spacing: normal;
  color: $dark;
}

.hoverHint {
  visibility: hidden;
  margin-left: 10px;
  font-size: 10px;
  font-weight: 500;
  line-height: 1.2;
  color: $theme1;
  grid-column: 2 / 3;
  height: 35px;
  line-height: 35px;
}

:global(.react-toggle) {
  & ~ span {
    margin-top: 7px;
  }
}

.fieldContent:hover {
  .hoverHint {
    visibility: visible;
  }
}

.hint {
  display: block;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #98969d;
  margin-top: 2px;
  width: 100%;
  grid-row: 2 / 3;
  grid-column: 1 / 3;
}

.permanentHint {
  grid-column: 1 / 3;
}

.error {
  border-radius: 2px;
  border: 1px solid $danger !important;
}

.withoutBorder {
  border: none !important;
}

.fullWidth {
  width: 100%;
  margin-bottom: 10px;
  .fieldContent {
    width: 100%;
  }
}

.required::before {
  content: '* ';
  color: $danger;
}

:global {
  .input-group {
    .form-control {
      height: auto;
    }
  }
}

:global(.input-group-text) {
  &.disabled {
    border: none;
    z-index: 1;
    border-right: 1px solid $super-light;
    color: $super-light;
    background-color: $light-pink;
  } 
}

.fileInputContainer {
  position: relative;
  max-width: 424px;
  .fileInput {
    width: 200px;
    height: 166px;
    border: none !important;
  }
}

.flexible {
  display: flex;
  position: relative;
  width: auto;
  padding-right: 15px;
  padding-left: 15px;

  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  font-family: NotoSansSC, 'Noto Sans SC';
  font-size: 14px;
  line-height: 22px;
  color: $black;
  text-align: left;
  margin: auto 0px;
}

.flexibleFieldContent {
  display: flex;
}

.flexibleFieldComponent {
  width: auto;
}

.flexibleFieldContent:hover {
  .hoverHint {
    visibility: visible;
  }
}


  .urlInputGroup {
    &:global(.input-group) {
      input {
        width: 424px;
      }
    }
    :global(.input-group-append) {
      span {
        width: 36px;
        height: 36px;
        padding: 0;
        border: none !important;
        cursor: pointer;
        > div {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          > svg {
            color: $dark;
          }
        }
      }
    }
  }

.checkbox {
  height: 36px;
  display: flex;
  align-items: center;
  label {
    color: $dark;
    margin-right: 8px;
  }
  input[type=checkbox]+label:before {
    margin-top: -2px;
  }
}

.multiImageInput {
  .imageFormInputWithList {
    display: flex;
    .selectImageList {
      padding-left: 20px;
      color: $dark;
      :global(.input-group-append) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .imageInput {
      position: relative;
      box-sizing: content-box;
      border: dashed 1px $super-light;
      background-color: $white;
      width: max-content;
      max-width: 500px;
      max-height: 500px;
      > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      &.previewMultipleInput, &.emptyImageInput {
        width: 318px;
        height: 166px;
      }
      &.showSolidBorder {
        border: 1px solid $super-light;
      }
      &.showErrorBorder {
        border-color: $danger;
      }
    }
  }
  .imageFormInputHint {
    display: block;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: #98969d;
    margin-top: 2px;
    width: 100%;
  }
}

.fitContent {
  width: fit-content !important;
  height: fit-content !important;
}

.radioColumn {
  display: flex;
  flex-direction: column;
  margin-top: 6px;
  :global(.form-check) {
    padding-left: 0;
  }
}

.removeBorder {
  border: none !important;
}