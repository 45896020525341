.container {
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
  .hiddenSpace {
    position: absolute;
    height: 1px;
    visibility: 'hidden';
  }
  .stickyPart {
    position: sticky;
    left: 0;
    height: 100%;
  }
}