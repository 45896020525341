@import 'colors.scss';

.error {
  border-radius: 2px;
  border: 1px solid $danger !important;

  &:global(.react-toggle) {
    border: none !important;
    :global(.react-toggle-track) {
      border: 1px solid $danger;
    }
  }

  :global(.react-tagsinput ) {
    border: none !important;
  }
}