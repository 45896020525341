@import 'colors.scss';

.tips~span.wording {
  display: block;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #98969d;
  margin-top: 2px;
  width: 100%;
}