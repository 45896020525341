@import 'fonts.scss';

.editLimitaionContainer {
  background: $white3;
}

.limitationSettingContainer {
  margin-top: 4px;
}

.borderTop {
  position: sticky;
  top: 0px;
  height: 2px;
  z-index: 6;
  background-color: $white3;
}

.audienceDefinition {
  background-color: $white;
  position: sticky;
  padding-left: 30px;
  padding-right: 70px;
  top: 2px;
  z-index: 5;
  box-shadow: 0 3px 5px -3px rgba(97, 95, 103, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div {
    padding-top: 1px;
    height: 51px;
  }
  .importAudience {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: $dark;
    cursor: pointer;
    span {
      @include base-font;
      margin-left: 6px;
    }
  }
}

.shadowCoverBottom {
  position: sticky;
  top: 42px;
  height: 3px;
  z-index: 6;
  background-color: $white;
}

.settingContainer {
  margin-top: 40px;
  display: flex;
}

.limitationSetting {
  background: $white;
  padding: 40px 69px 40px 30px;
  display: flex;
  flex-direction: column;

  .limitationSet {
    width: 100%;
    margin: 0;

    :global(.form-group.row) {
      margin: 0;
      margin-bottom: 20px;
      align-items: center;

      > :global(.form-label) {
        flex-basis: 170px;
        margin: 0px;
        padding-right: 30px;
      }
    }

    .tags {
      flex: 1;

      .dealId > span {
        display: flex;
      }
    }

    :global {
      .react-tagsinput > span {
        display: flex;
      }
    }

    :global {
      .react-tagsinput-input {
        border: none;
        flex: 1;
      }
    }
  }
}

.dealIdHint {
  display: block;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #98969d;
  margin-top: 2px;
  width: 100%;
}

.generalTitle {
  display: flex;
  align-items: center;
  color: $dark;
  font-size: 17px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.18;
  letter-spacing: normal;
  &::before {
    width: 10px;
    height: 20px;
    margin-right: 6px;
    background: $dark;
    content: '';
  }
  margin-bottom: 40px;
}