@import 'colors.scss';

.component .price {
  width: 184px;
  border-radius: 2px;
  border: 1px solid $super-light;
  flex: unset;
}

.bidPrice {
  margin-left: 4px;
}

.component {
  background: $white;
  box-shadow: 5px 2px 5px 0 rgba(97, 95, 103, 0.5);
  padding: 40px 30px;

  :global {
    .form-check {
      padding-left: 0;
    }
  }
  .bidControl {
    > :global(.row) {
      margin-bottom: 2px;
    }
  }
}

.billingEvent {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: $dark;
}

.bidPrice {
  margin-left: 0px;
  margin-top: 10px;
}

.frequencyControl {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: $dark;

  :global(.errorTip) {
    margin-top: 5px;
    min-height: 14px;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: normal;
    width: 100%;
    display: block;
    color: $danger;
  }
  .maxFrequency, .intervalDays {
    width: 70px;
    &.error {
      border-color: $danger;
    }
  }
}
